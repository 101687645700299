import es0 from './group-1.json';
import es1 from './group-2.json';
import es2 from './group-3.json';

import es3 from './group-4.json';

import es4 from './group-5.json';

import es5 from './group-6.json';

const quiz = {
  0: es0[0],
  1: es1[0],
  2: es2[0],
  3: es3[0],
  4: es4[0],
  5: es5[0],
};

export default quiz;
