import en0 from "./group-1.json";
import en1 from "./group-2.json";
import en2 from "./group-3.json";

import en3 from "./group-4.json";

import en4 from "./group-5.json";

import en5 from "./group-6.json";


const quiz = {
  0: en0[0],
  1: en1[0],
  2: en2[0],
  3: en3[0],
  4: en4[0],
  5: en5[0],
};

export default quiz;
